/** @jsx onetext.jsx.element */

import type { ComponentChildren } from 'preact';

import type { OneText } from '../../global';

import CookieGraphic from './images/cookie-graphic.png';

const SUPPRESSED_ROUTES = [
    '/products' // for testing
];

declare let onetext : OneText;

const {
    Popup,
    Button,
    EmailField,
    PhoneField,
    Page,
    Row,
    Columns,
    Column,
    Quiz,
    QuizOption,
    CloseButton,

    POPUP_LAYOUT
} = onetext.component.consent.popup;

type CookieStoreButtonProps = {
    children : ComponentChildren,
};

const CookieStoreButton = ({
    children
} : CookieStoreButtonProps) : JSX.Element => {
    return (
        <Button
            className={ `w-full h-12 rounded text-center text-white bg-[#83BFB5] hover:bg-[#A5D0C9] focus:bg-[#A5D0C9] focus:outline-none font-normal tracking-[4px] uppercase` }
        >
            { children }
        </Button>
    );
};

type CookieStorePageProps = {
    children : ComponentChildren,
    openKeyboardOnSubmit ?: boolean,
    fadeIn ?: boolean,
};

const CookieStorePage = ({
    children,
    openKeyboardOnSubmit,
    fadeIn = false
} : CookieStorePageProps) : JSX.Element => {
    return (
        <Page
            className={
                `bg-[#F0EEE7] ` +
                `w-full ` +
                `h-full ` +
                `pb-8 ` +
                `md:pb-14 ` +
                `futura ${
                fadeIn
                    ? 'fade-in-up'
                    : '' }`
            }
            openKeyboardOnSubmit={ openKeyboardOnSubmit }
        >
            { children }
        </Page>
    );
};

const CookieStorePopup = () : JSX.Element | null => {
    const currentPath = window.location.pathname;
    const isSuppressed = SUPPRESSED_ROUTES.some(route => currentPath.startsWith(route));

    const [ shouldShowPopup, setShouldShowPopup ] = onetext.jsx.useState(false);

    onetext.jsx.useEffect(() => {
        const randomDelay = Math.floor(Math.random() * (15_000 - 10_000 + 1)) + 10_000;
        const delay = onetext.sdk.isDevelopment()
            ? 0
            : randomDelay;

        const timer = setTimeout(() => {
            setShouldShowPopup(true);
        }, delay);

        return () => clearTimeout(timer);
    }, []);

    if (!onetext.sdk.isPreviewMode() || isSuppressed || !shouldShowPopup) {
        return null;
    }

    return (
        <Popup layout={ POPUP_LAYOUT.FULLSCREEN }>
            <CookieStorePage
                openKeyboardOnSubmit
                fadeIn>
                <Row>
                    <Columns>
                        <Column>
                            <div
                                class={ 'mb-10' }
                                style={ { fontSize: 26 } }>
                                { 'Please Accept This Cookie' }
                            </div>
                            <p class={ 'text-3xl font-semibold uppercase tracking-wider text-center' }>{ 'Unlock' }</p>
                            <p class={
                                'my-2 text-7xl uppercase tracking-wider text-center font-semibold'
                            }>
                                { '15% off' }
                            </p>
                            <p class={
                                'text-3xl font-light italic uppercase tracking-wider text-center'
                            }>
                                { 'Everything' }
                            </p>
                            <p class={ 'text-md font-light leading-relaxed text-center mt-3 mb-6 w-[280px]' }>
                                { 'To claim your discount, tell us your favorite type of cookie:' }
                            </p>
                            <Quiz propertyName={ 'favorite_cookie' }>
                                <div class={ 'w-[264px] flex flex-col items-center gap-2.5' }>
                                    <QuizOption propertyValue={ 'chocolate_chip' }>
                                        <CookieStoreButton>
                                            { 'Chocolate Chip' }
                                        </CookieStoreButton>
                                    </QuizOption>
                                    <QuizOption propertyValue={ 'oatmeal_raisin' }>
                                        <CookieStoreButton>
                                            { 'Oatmeal Raisin' }
                                        </CookieStoreButton>
                                    </QuizOption>
                                    <QuizOption propertyValue={ 'sugar' }>
                                        <CookieStoreButton>
                                            { 'Sugar Cookie' }
                                        </CookieStoreButton>
                                    </QuizOption>
                                    <QuizOption propertyValue={ 'peanut_butter' }>
                                        <CookieStoreButton>
                                            { 'Peanut Butter' }
                                        </CookieStoreButton>
                                    </QuizOption>
                                </div>
                            </Quiz>
                        </Column>
                        <Column className={ 'col-span-1 hidden md:flex' }>
                            <img src={ CookieGraphic } />
                        </Column>
                    </Columns>
                </Row>
                <CloseButton />
            </CookieStorePage>

            <CookieStorePage openKeyboardOnSubmit>
                <Row>
                    <Columns>
                        <Column>
                            <div
                                class={ 'mb-10' }
                                style={ { fontSize: 26 } }>
                                { 'Please Accept This Cookie' }
                            </div>
                            <p class={ 'text-3xl font-semibold uppercase tracking-wider text-center' }>{ 'Unlock' }</p>
                            <p class={
                                'my-2 text-7xl uppercase tracking-wider text-center font-semibold'
                            }>
                                { '15% off' }
                            </p>
                            <p class={
                                'text-3xl font-light italic uppercase tracking-wider text-center'
                            }>
                                { 'Everything' }
                            </p>
                            <p class={ 'text-md font-light leading-relaxed text-center mt-3 mb-6 w-[280px]' }>
                                { 'Sign up with your email address to claim your 15% discount' }
                            </p>
                            <div class={ 'w-[264px] flex flex-col items-center gap-2.5' }>
                                <EmailField autofocus={ true } />
                                <CookieStoreButton>
                                    { 'Continue' }
                                </CookieStoreButton>
                            </div>
                        </Column>
                        <Column className={ 'col-span-1 hidden md:flex' }>
                            <img src={ CookieGraphic } />
                        </Column>
                    </Columns>
                </Row>
                <CloseButton />
            </CookieStorePage>

            <CookieStorePage>
                <Row>
                    <Columns>
                        <Column>
                            <div
                                class={ 'mb-10' }
                                style={ { fontSize: 26 } }>
                                { 'Please Accept This Cookie' }
                            </div>
                            <p class={
                                'text-[40px] font-semibold uppercase tracking-wider text-center md:whitespace-nowrap'
                            }>
                                { 'Finish signing up' }
                            </p>
                            <p class={
                                'text-md font-light leading-relaxed ' +
                                'text-center mt-3 mb-6 w-[280px] ' +
                                'md:whitespace-nowrap'
                            }>
                                { 'with text to activate your 15% off discount' }
                            </p>
                            <p class={ 'text-[9px] md:text-[10px] font-light leading-relaxed text-center w-[260px]' }>
                                {
                                    'By providing your cell number, you agree to receive occasional marketing texts. ' +
                                    'Purchase not necessary. Reply HELP for help; STOP to opt out.'
                                }
                            </p>
                            <p class={
                                'text-[9px] md:text-[10px] font-light leading-relaxed text-center mb-6 w-[300px]'
                            }>
                                <a
                                    href={ 'https://cadenlane.com/CadenLanePages/terms-of-service' }
                                    target={ '_blank' }
                                    rel={ 'noopener noreferrer' }
                                    class={ 'underline' }>{ 'Terms' }
                                </a>
                                { ' & ' }
                                <a
                                    href={ 'https://cadenlane.com/CadenLanePages/privacy-policy' }
                                    target={ '_blank' }
                                    rel={ 'noopener noreferrer' }
                                    class={ 'underline' }>{ 'Privacy' }
                                </a>
                            </p>
                            <div class={ 'w-[264px] flex flex-col items-center gap-2.5' }>
                                <PhoneField autofocus={ true } />
                                <CookieStoreButton>
                                    { 'Activate 15% Off' }
                                </CookieStoreButton>
                            </div>
                        </Column>
                        <Column className={ 'col-span-1 hidden md:flex' }>
                            <img src={ CookieGraphic } />
                        </Column>
                    </Columns>
                </Row>
                <CloseButton />
            </CookieStorePage>

            <CookieStorePage>
                <Row>
                    <Columns>
                        <Column>
                            <div
                                class={ 'mb-10' }
                                style={ { fontSize: 26 } }>
                                { 'Please Accept This Cookie' }
                            </div>
                            <p class={
                                'text-7xl ' +
                                'uppercase ' +
                                'tracking-wider ' +
                                'text-center ' +
                                'font-semibold ' +
                                'md:whitespace-nowrap'
                            }>
                                { 'You\'re in!' }
                            </p>
                            <p class={ 'text-2xl font-light tracking-wider text-center w-[280px] mt-3 mb-10' }>
                                { 'Check your texts for your 15% off discount.' }
                            </p>
                            <div class={ 'w-[264px] flex flex-col items-center gap-2.5' }>
                                <CookieStoreButton>
                                    { 'Back to site' }
                                </CookieStoreButton>
                            </div>
                        </Column>
                        <Column className={ 'col-span-1 hidden md:flex' }>
                            <img src={ CookieGraphic } />
                        </Column>
                    </Columns>
                </Row>
                <CloseButton />
            </CookieStorePage>
        </Popup>
    );
};

void onetext.jsx.render({
    element: (
        <CookieStorePopup />
    ),
    container: document.body
});
